<template>
  <div class="lender-wrapper">
    <div class="lender-banner-wrap">
      <div class="lender-banner-content">
        <div class="lender-tip wow animate__fadeInLeft">Dengan menjadi</div>
        <div class="lender-btn wow animate__fadeInLeft">Lender di BantuSaku</div>
        <div class="lender-text wow animate__fadeInLeft">Anda mendapatkan tawaran yang menarik dengan resiko yang terjaga.</div>
      </div>
    </div>
    <div class="world-wrap">
      <div class="world-content">
        <div class="world-item-box">
          <el-row :gutter="0">
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="world-card-item wow animate__fadeInUp">
                <img class="world-img" src="../assets/img/lender/world1.png" alt="world1">
                <div>Lender</div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="world-card-item wow animate__fadeInUp">
                <img class="bantusaku" src="../assets/img/lender/world2.png" alt="world2">
                <div>Trusted Platform</div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="world-card-item wow animate__fadeInUp">
                <img class="world-img" src="../assets/img/lender/world3.png" alt="world3">
                <div>Borrower</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="advantage-content">
          <div class="advantage-item-box">
            <el-row :gutter="70">
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInUp">
                  <img class="advantage1-title" src="../assets/img/lender/advantage1.png" alt="advantage1">
                  <div class="advantage1-text">Teknologi sistem yang paling baru dan menggunakan sistem manajemen resiko yang canggih</div>
                  <img class="advantage1-img" src="../assets/img/lender/advantage5.png" alt="advantage5">
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInUp">
                  <img class="advantage1-title" src="../assets/img/lender/advantage2.png" alt="advantage1">
                  <div class="advantage1-text">Terlindungi oleh Hukum karena BantuSaku telah berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)</div>
                  <img class="advantage1-img" src="../assets/img/lender/advantage6.png" alt="advantage6">
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInUp">
                  <img class="advantage1-title" src="../assets/img/lender/advantage3.png" alt="advantage1">
                  <div class="advantage1-text">Memberikan suku bunga tahunan yang menarik dengan resiko rendah</div>
                  <img class="advantage1-img" src="../assets/img/lender/advantage7.png" alt="advantage7">
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInUp">
                  <img class="advantage1-title" src="../assets/img/lender/advantage4.png" alt="advantage1">
                  <div class="advantage1-text">Turut serta dalam membantu perekonomian masyarakat Indonesia</div>
                  <img class="advantage1-img" src="../assets/img/lender/advantage8.png" alt="advantage8">
                </div>
              </el-col>
          </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
export default {
  data() {
    return {
    };
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    sessionStorage.setItem('activeTab', '2')
  },
}
</script>

<style lang="scss" scoped>
.lender-wrapper{
  width: 100%;
  min-height: 100vh;
  .lender-banner-wrap{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(92deg, #28543F 0%, #28543F 16%, rgba(40,84,72,0.35) 100%, rgba(40,84,72,0.35) 100%), url("../assets/img/home/3.jpg");
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 172px;
    box-sizing: border-box;
    .lender-banner-content{
      width: 600px;
      color: #fff;
      font-weight: 700;
      .lender-tip{
        font-size: 50px;
        margin-left: 14px;
      }
      .lender-btn{
        width: 500px;
        height: 120px;
        background: #B3E872;
        border-radius: 75px;
        line-height: 120px;
        text-align: center;
        font-size: 40px;
        color: #28543F;
        margin: 14px 0 20px 0;
      }
      .lender-text{
        font-size: 30px;
        margin-left: 14px;
      }
    }
  }
  .world-wrap{
    width: 100%;
    position: relative;
   .world-content{
      max-width: 1580px;
      margin: 0 auto;
      background: url("../assets/img/lender/world.png") no-repeat;
      background-position: top center;
      background-size: 80%;
      overflow: hidden;
      padding: 140px 0 0 0;
      box-sizing: border-box;
      .world-item-box{
        margin-bottom: 180px;
        .world-card-item{
          text-align: center;
          font-size: 34px;
          font-weight: bold;
          .world-img{
            width: 284px;
            height: 284px;
            margin: 20px 0 40px 0;
          }
          .bantusaku{
            width: 322px;
            height: 322px;
            margin-bottom: 21px;
          }
        }
      }
      .advantage-content{
        .advantage-item-box{
          .advantage-card-item{
            margin-bottom: 80px;
          }
          .advantage1-title{
            width: 64px;
            height: 64px;
          }
          .advantage1-text{
            width: 306px;
            min-height: 184px;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            margin-top: 40px;
          }
          .advantage1-img{
            width: 326px;
            height: 304px;
          }
        }
      }
    }
  }
}
</style>